exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".root_1Zme7 {\n    width: 100%;\n    border-collapse: collapse;\n    margin: 0;\n    margin-top: 10px;\n    margin-bottom: 10px;\n}\n\n.cell_Nyqjb {\n    width: 33%;\n    text-align: center;\n    vertical-align: bottom;\n}\n\n.line_1zAem:first-child .cell_Nyqjb {\n    border: 2px solid transparent;\n    border-bottom-color: black;\n}\n\n.box_QMEHT {\n    width: 150px;\n    height: 75px;\n    border: 1px dashed black;\n    margin-left: auto;\n    margin-right: auto;\n    position: relative;\n}\n\n.button_2lIbQ {\n    width: 60px;\n    height: 30px;\n    position: absolute;\n    background-color: blue;\n    color: white;\n    top: 22px;\n    left: 45px;\n}\n\n.error_28RKm {\n    margin-top: 3px;\n    color: red;\n    margin-bottom: 5px;\n}\n", ""]);

// exports
exports.locals = {
	"root": "root_1Zme7",
	"cell": "cell_Nyqjb",
	"line": "line_1zAem",
	"box": "box_QMEHT",
	"button": "button_2lIbQ",
	"error": "error_28RKm"
};