exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".root_QutRL {\n    font-family: Arial, Helvetica, sans-serif;\n    text-align: end;\n    font-size: 12px;\n}\n\n.root_QutRL span {\n    color: #323941;\n}\n\n.counter_2wAuV {\n    color: #262a31;\n}\n", ""]);

// exports
exports.locals = {
	"root": "root_QutRL",
	"counter": "counter_2wAuV"
};