exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".header_z9wQ1 {\n    width: 100%;\n}\n\n.newPage_1dkZ9 {\n    page-break-before: always;\n}\n", ""]);

// exports
exports.locals = {
	"header": "header_z9wQ1",
	"newPage": "newPage_1dkZ9"
};