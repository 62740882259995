exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".noResize_2OOek {\n    resize: none;\n    overflow-y: hidden;\n}\n", ""]);

// exports
exports.locals = {
	"noResize": "noResize_2OOek"
};